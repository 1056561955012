@import '../../scss/mixins';
.home {
	display: flex;
	@include respond(tablet) {
		flex-direction: column;
	}
	&--visual {
		flex: 0 0 50%;
		// background: linear-gradient(
		// 		to bottom,
		// 		rgba(255, 255, 255, 1) 0%,
		// 		rgba(0, 0, 0, 0.5) 25%,
		// 		rgba(0, 0, 0, 0.9) 40%,
		// 		rgba(0, 0, 0, 0.8) 80%,
		// 		rgba(0, 0, 0, 1) 100%
		// 	),
		// 	url('../../assets/img/cover.jpg');
		// rgba(73, 191, 76, 1)
		background: linear-gradient(
				to bottom,
				rgba(255, 255, 255, 1) 0%,
				rgba(73, 191, 76, 0.5) 25%,
				rgba(73, 191, 76, 0.9) 40%,
				rgba(73, 191, 76, 0.95) 80%,
				rgba(73, 191, 76, 1) 100%
			),
			url('../../assets/img/cover.jpg');

		min-height: 100vh;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		padding: 2rem 2rem;

		@include respond(tablet) {
			padding: 1rem;
			width: 100%;
		}

		&-content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			min-height: 80vh;
			// padding: 5rem;
		}

		&-info {
			width: 100%;
			padding: 4rem 8rem;
			display: flex;
			justify-content: space-between;

			@include respond(tablet) {
				padding: 4rem 2rem;
			}
		}
	}
	&--action {
		flex: 0 0 50%;

		@include respond(tablet) {
			width: 100%;
		}

		&-container {
			padding: 2rem;
		}
	}
}

.line {
	width: 60%;
	// border: 0.2rem solid green;
	border: 0.2rem solid #fff;
}

.logo {
	cursor: pointer;
	margin: auto 0;
	width: auto;
	&--img {
		height: 8rem;

		@include respond(tablet) {
			height: 6rem;
		}
	}
}

.info {
	&--col {
		display: flex;
		flex-direction: column;
	}
	&--card {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&--icon {
		width: 5rem;
		height: 6rem;
		fill: white;

		margin-right: 2rem;

		@include respond(tablet) {
			width: 4rem;
			height: 5rem;
			margin-right: 2rem;
		}
	}
}

.footer {
	border-top: 2px solid #00d29a;

	&--flex {
		display: flex;
		justify-content: space-between;
	}
}
