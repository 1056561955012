@import '../../scss/mixins';

.booking {
	padding: 4rem 4rem 0rem 4rem;
	min-height: 70vh;
	width: 100%;
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	@include respond(tablet) {
		padding: 2rem;
	}

	&--header {
		width: 100%;
		padding: 2rem;
		// border-bottom: 0.5px solid #eee;
		border-bottom: 2px solid #00d29a;
	}
}

.confirmation {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4rem 0;
	&--visual {
		max-width: 200px;
	}
	&--img {
		@include ResponsiveImage();
	}
}

.stockholm {
	color: #00b180;
	font-size: 18px;
	font-weight: 700;
}

.rating--icon {
	width: 4rem;
	height: 3rem;
	fill: currentColor;
}
