.form {
	width: 99%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	@include respond(tablet) {
		width: 100%;
	}

	&--check-group {
		display: inline-flex;
		align-items: center;

		input {
			margin-right: 1rem;
		}
	}

	&--group {
		width: 100%;
		@include respond(tablet) {
			width: 100%;
		}

		&-flex {
			display: flex;
			justify-content: space-between;
			width: 100% !important;
			& > * {
				flex: 0 0 48%;
			}
		}

		// input and text area which are direct children to group
		& > input,
		& > textarea {
			font-size: 1.5rem;
			font-family: inherit;
			color: inherit;
			padding: 1rem 2rem;
			border-radius: 5px;
			border: 1px solid #dadce0;
			// background-color: var(--color-grey-light-2);
			width: 100% !important;
			display: block;
			transition: all 0.3s;

			&:focus {
				outline: none;
				// background-color: var(--color-grey-light-3);
				box-shadow: 0 1rem 2rem rgba(var(--color-black), 0.1);
				border: 1px solid var(--color-primary);
			}

			&:focus:invalid {
				border: 1px solid var(--color-tertiary);
			}

			&::-webkit-input-placeholder {
				color: var(--color-grey-text);
			}
		}

		.input--class {
			padding-left: 5rem !important;
			font-size: 1.5rem;
			font-family: inherit;
			color: inherit;
			padding: 1rem 0;
			border-radius: 5px;
			border: 1px solid #dadce0;
			// background-color: var(--color-grey-light-2);
			width: 100% !important;
			display: block;
			transition: all 0.3s;

			&:focus {
				outline: none;
				// background-color: var(--color-grey-light-3);
				box-shadow: 0 1rem 2rem rgba(var(--color-black), 0.1);
				border: 1px solid var(--color-primary);
			}

			&:focus:invalid {
				border: 1px solid var(--color-tertiary);
			}

			&::-webkit-input-placeholder {
				color: var(--color-grey-text);
			}
		}

		label {
			font-size: 1.4rem;
			font-weight: 700;
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
			display: block;
			transition: all 0.3s;
		}

		input:placeholder-shown + label {
			opacity: 0;
			visibility: hidden;
		}
	}

	&--btn-group {
		display: flex;
		justify-content: space-between;
	}
}

// Button Component
.btn {
	&,
	&:link,
	&:visited {
		font-family: var(--noto-font);
		font-weight: 500;
		text-transform: capitalize;
		@include ResetLink();
		padding: 1.1rem 2.8rem;
		line-height: 1.4rem;
		display: inline-block;
		// border-radius: 0.1rem;
		border-radius: 10px;
		transition: all 0.2s;
		position: relative;
		font-size: var(--default-font-size);
		color: var(--color-white);
		background: var(--color-primary);
		border: 0.2 solid transparent;
		cursor: pointer;

		&:hover {
			color: var(--color-white);
			box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.2);
			background-position: right center;

			&::after {
				opacity: 0;
			}
		}

		&:active,
		&:focus {
			outline: none;
			box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
		}
	}

	&--white {
		background-color: var(--color-white);
		color: var(--color-grey-dark-1);

		&::after {
			background-color: var(--color-white);
		}
	}
}

.btn--cta {
	border: none;
	border-radius: 10px;
	// box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.btn--icon {
	&-reverse {
		flex-direction: row-reverse;
		justify-content: flex-end;
	}

	display: inline-flex;
	color: var(--color-white);
	font-size: 1.6rem;
	align-items: center;

	padding: 1.1rem 2.8rem;

	border: none;
	border-radius: 0.5rem;

	&-shape {
		width: 2rem;
		height: 1.6rem;
		fill: currentColor;
	}

	& > *:not(:first-child) {
		margin-left: 0.6rem;
	}
}
.btn--clear {
	background: transparent;
	color: var(--color-black);
}

.btn--text {
	font-size: var(--default-font-size);
	color: var(--color-primary);
	text-decoration: none;
	display: inline-block;
	border: none;
	padding: 1px 2px;
	transition: all 0.2s;

	&:link,
	&:visited {
		font-size: var(--default-font-size);
		color: var(--color-primary);
		text-decoration: none;
		display: inline-block;
		border: none;
		padding: 3px;
		transition: all 0.2s;
	}

	&:hover {
		color: var(--color-white);
		background-color: var(--color-primary);
		box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
	}

	&:active {
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	}
}
