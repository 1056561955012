// ----------------------- base ---------------------------- //
*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-size: 62.5%; // 1rem = 10px
}

body {
	overflow-x: hidden;
	background: var(--color-white);
}
main {
	padding: 0 10rem;

	@include respond(tablet) {
		padding: 1.5rem;
	}
}

::selection {
	background-color: var(--color-primary);
	color: var(--color-white);
}
