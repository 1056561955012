body {
	font-size: var(--default-font-size);
	font-family: var(--default-font-family);

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.heading {
	&--large {
		font-family: var(--open-sans-font);
		font-weight: 900;
		line-height: 1.5;
		font-size: 4.2rem;

		@include respond(tablet) {
			font-size: 2.5rem;
		}
	}
	&--sub {
		font-family: var(--open-sans-font);
		font-weight: 700;
		line-height: 1.5;
		font-size: 3rem;

		@include respond(tablet) {
			font-size: 2.4rem;
		}
	}
	&--primary {
		font-family: var(--open-sans-font);
		font-weight: 700;
		font-size: 1.8rem;
		line-height: 1.5;
	}
}

.text {
	&--bold {
		font-weight: bold;
	}
	&--capitalize {
		text-transform: capitalize;
	}
	&--emphasy {
		font-style: italic;
		font-weight: bold;
	}
	&--normal {
		font-family: var(--roboto-font);
		font-weight: 300;
		font-size: var(--default-font-size);
	}

	&--black {
		color: var(--color-black);
	}

	&--green {
		color: var(--color-primary);
	}

	&--grey {
		color: var(--color-grey-text);
		line-height: 2;
	}
	&--white {
		color: var(--color-white);
	}
}
